// color variants
@import 'src/assets/scss/_themes-vars.module.scss';

a {
    color: #3c3f42;

    &:hover {
        color: #3c3f42 !important;
    }
}

.nav_search_wrap {
    position: relative;

    i {
        position: absolute;
        left: 25px;
        top: 16px;
        color: $orange;

        svg {
            font-size: 18px;
        }
    }
}

.scrollbar-container {
    > {
        .MuiPaper-rounded {
            margin: 0px;
        }
    }

    h6 {
        color: $orange;
        margin-bottom: 0px;
        font-weight: 600;
        padding-left: 20px;
        font-size: 10px;
        display: none;
    }

    .Mui-expanded {
        min-height: 0px;

        &::before {
            opacity: 1;
        }

        p {
            color: $orange;
        }

        > {
            svg {
                color: $orange;
            }
        }
    }
}

.nav_search {
    border-radius: 0px;
    padding: 16px 20px;
    padding-left: 48px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #0000001F;
    border-top: 1px solid #0000001F;
    font-size: 14px;
    line-height: 21px;
    //margin-bottom: 16px;

    &:focus {
        outline: none;
    }
}

.text {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    font-weight: bold;
}

.sortableTreeClass {
    > {
        div {
            height: calc(100vh - 402px) !important;
        }
    }

    position: relative;
    //height: 100vh !important;

    .ReactVirtualized__Grid__innerScrollContainer {

        .rstcustom__collapseButton,
        .rstcustom__expandButton {
            &::after {
                width: 6px;
                height: 6px;
                border: none;
                border-top: 1px solid $orange;
                display: inline-block;
                border-right: 1px solid $orange;
                transform: rotate(132deg);
                left: 6px;
            }

        }


        button[style="left: 82.5px;"] {
            &::after {
                left: 0px;
            }
        }

        .rstcustom__expandButton {
            &::after {
                transform: rotate(45deg);
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .rstcustom__collapseButton+div {
            color: $orange;
            font-weight: bold;
        }

        .rstcustom__toolbarButton svg {
            font-size: 20px;
            color: #000;
            vertical-align: middle;
        }

        .rstcustom__row {
            padding: 0px 0px 0px 20px;
            flex: 1;
        }

        .rstcustom__node {
            border-bottom: 1px solid #0000001F;
            padding: 10px 20px;
            padding-left: 30px;
            font-size: 14px;
            line-height: 22px;
            color: #000;
            overflow-x: auto;
            -ms-overflow-style: none; 
            scrollbar-width: none; 

            &:first-child {
                padding-left: 20px;

                // .rstcustom__collapseButton,
                //.rstcustom__expandButton {
                //   left: 4px !important;
                //}
            }
        }
        .rstcustom__node::-webkit-scrollbar {
            display: none;
        }


        .rstcustom__rowContents {
            justify-content: flex-start;
        }

        .rstcustom__node {
            .rstcustom__collapseButton {
                ~.rstcustom__rowWrapper {
                    .rstcustom__row {
                        .rstcustom__rowContents {
                            justify-content: space-between;
                        }
                    }

                }
            }
        }

        .rstcustom__rowWrapper {
            .rstcustom__lineBlock:empty {
                &:nth-child(3) {
                    display: block;
                    width: 15px !important;
                }

                &:nth-child(4)+div {
                    padding-left: 43px;
                }

                &:nth-child(5)+div {
                    padding-left: 53px;
                }

                &:nth-child(6)+div {
                    padding-left: 63px;
                }
            }

            &:hover {
                opacity: 1;
            }
        }

        .rstcustom__lineBlock:empty,
        .rstcustom__rowToolbar:empty {
            display: none;
        }

        .rstcustom__collapseButton+.rstcustom__rowContents {
            display: block !important;
        }

        .rstcustom__rowLabel {
            padding-right: 0px;

            span {
                a {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 8px;
                    text-overflow: ellipsis;
                    max-width: 145px;
                    white-space: nowrap;
                    overflow: hidden;
                    -ms-overflow-style: none; 
                    scrollbar-width: none;
                }
                a::-webkit-scrollbar {
                    display: none;
                }

                input {
                    outline: none;
                    border: 1px solid $orange;
                }

                svg {
                    vertical-align: bottom;
                }
            }
        }

        //.rstcustom__collapseButton,
        // .rstcustom__expandButton {
        //    left: 14px !important;
        // }
    }

    .rst__virtualScrollOverride::-webkit-scrollbar {
        display: none;
    }

    .rst__virtualScrollOverride {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .MuiPaper-rounded {
        margin-bottom: 0px;
    }

}

#menu- {
    .MuiPaper-rounded {
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px !important;
    }

}

.css-1hhrn5n {
    padding: 12px 20px;
}
.draft-btn {
    padding: 12px 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    color: #212121;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border-top: 1px solid #0000001F;
    border-right: 1px solid #0000001F;
    position: fixed;
    left: 0px;
    width: 100%;
    z-index: 100;
    bottom: 52px;
    background: $paper;
    width: 284px;
    &:hover{
        color: #212121 !important;
    }
}

nav {
    .clientsSelect {
        padding-bottom: 0px;
    }
}

.popup1 {
    h2 {
        font-size: 20px;
        font-weight: 700;
    }
}

.MuiDrawer-docked {
    .MuiDrawer-paper {
        overflow-y: hidden;
    }
}
