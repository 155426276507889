// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

body {
    overflow: hidden;
    margin: 0px;
}

a:hover {
    color: $orange;
    text-decoration: none;
}

button {
    cursor: pointer;
}

.cursor_pointer {
    cursor: pointer;
}

.clientsSelect {
    padding: 12px 20px 9px 20px;
}

.clientsSelect h6 {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    margin: 0px 0px 5px;
    color: #000;
    font-weight: bold;
}

.clientsSelect .custom > div {
    padding: 0px !important;
}

.clientsSelect .custom {
    &::before {
        display: none;
    }

    &:hover {
        border: none;
    }
}

.clientsSelect .custom svg {
    position: static;
    color: $orange;
}

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.MuiPaper-rounded {
    box-shadow: none;
}

.customForm {
    // .MuiBox-root~.MuiGrid-container {
    //     margin-left: 5px;
    //     display: flex;
    //     justify-content: space-between;

    //     .MuiGrid-item {
    //         flex: 0 0 48%;

    //         .MuiGrid-item {
    //             flex: 0 0 100%;
    //         }
    //     }
    // }

    h5 {
        font-size: 18px;
        margin-bottom: 12px;
        line-height: 24px;
        font-weight: 600;
        color: $black;

        svg {
            vertical-align: bottom;
            cursor: pointer;
        }
    }

    label {
        //font-size: 12px;
        //font-weight: 700;
        //text-transform: uppercase;
        //color: $black;
        margin-bottom: 0px;

        //top: 4px;
        &:focus-visible {
            border-color: #4055f5;
        }
        span {
            &.MuiCheckbox-root {
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
    }

    input[type='text'],
    input[type='number'],
    input[type='datetime-local'] {
        border: 1px solid #ececec;
        border-radius: 4px;
        background: $paper;
        height: 42px;
        padding: 0px 16px;
        font-size: 13px;
        color: $black;
        font-weight: 400;
    }
    .MuiAutocomplete-inputRoot {
        input[type='text'],
        input[type='number'],
        input[type='datetime-local'] {
            height: 32px;
            padding: 0px 16px;
        }
    }

    .MuiOutlinedInput-root {
        margin-bottom: 10px;
        outline: none;
    }

    fieldset {
        border: 1px solid #ececec;
        border-radius: 4px;
        outline: none;
    }

    .MuiOutlinedInput-root {
        outline: none;
    }

    &.inActiveForm {
        opacity: 0.6;
    }

    .schedule {
        .MuiOutlinedInput-root {
            margin-bottom: 0px;
        }
    }

    // .customForm {
    //     .MuiBox-root~.MuiGrid-container {
    //         display: block;
    //     }
    // }

    // &.body {
    //     .css-636293+.MuiGrid-container {
    //         display: block;
    //     }

    //     // .customForm {
    //     //     .MuiGrid-container {
    //     //         display: flex;

    //     //         .css-636293~.MuiGrid-container .MuiGrid-item {
    //     //             flex: 0 0 48%;
    //     //         }

    //     //     }
    //     // }
    // }
}

textarea {
    resize: none;
}

// .MuiInputBase-root {
//     .MuiInput-root {
//         .MuiSelect-root {
//             &:hover:not(.Mui-disabled) {
//                 &::before {
//                     border-bottom: 1px solid rgba(0, 0, 0, 0.42);
//                 }
//             }
//         }
//     }
// }

.MuiDrawer-docked {
    .MuiDrawer-paper {
        top: 0px;
        border-right: 1px solid #0000001f;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .MuiDrawer-paper::-webkit-scrollbar {
        display: none;
    }
}

header.mui-fixed {
    display: none;
}

.inActiveForm {
    .MuiGrid-container {
        opacity: 0.5;
        pointer-events: none;
    }
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    justify-content: space-between;

    .MuiGrid-item {
        //padding: 0px;
        padding-top: 0px;
        overflow: visible !important;
        div[name="outer-box"] {
            margin-top: -2.4em !important;
        }
        .field-array {
            .MuiPaper-elevation  {
                .css-19midj6 {
                    &:first-child {
                        padding-left: 0px;
                    }
                }
            }

        }
        &.MuiGrid-grid-xs-true {
            .MuiPaper-elevation  {
                .css-19midj6 {
                    &:first-child {
                        padding-left: 16px;
                    }
                }
            }

        }
    }
}
.toggle_btn {
    + {
        .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
            margin-left: -16px;
        }
    }
}

.css-1r8eyd1 {
    margin-top: 0px;
    background: $paper;
    padding-left: 30px;

    .loading {
        left: 0px;
        top: 0px;
        margin-left: 100px;
        z-index: 100;
    }
}

.css-18b6iq3 {
    border-right: 1px solid #0000001f;
}

.MuiDataGrid-footerContainer {
    border: none;
}

.btn-info {
    background: $orange;
    border-color: $orange;

    &:hover {
        background: $orange;
    }
}

.button_container_row {
    text-align: left;
    z-index: 99;
    
    .button1 {
        box-shadow: none;
        outline: none;
        border-radius: 4px;
        text-align: center;
        text-transform: uppercase;
        background: $orange;
        color: $paper;
        border: 1px solid $orange;
    }
}

.footerBottom {
    position: relative;
    width: calc(100% - 284px);
    left: 284px;
    height: 80px;
    position: fixed;
    bottom: 0px;
    z-index: 1;
    background: $paper;
    box-shadow: 50px 10px 32px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 50px 10px 32px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 50px 10px 32px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 50px 10px 32px rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button_container {
        padding: 0px 10px 0px 30px;
        text-align: left;
        z-index: 99;

        .button1,
        .button2 {
            box-shadow: none;
            outline: none;
            padding: 12px 16px;
            border-radius: 4px;
            text-align: center;
            min-width: 96px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;

            i {
                vertical-align: middle;

                svg {
                    color: $paper;
                }
            }

            &.create-btn {
                padding-left: 45px;
                position: relative;

                i {
                    position: absolute;
                    top: 9px;
                    left: 16px;
                }
            }
        }

        .button1 {
            background: $orange;
            color: $paper;
            border: 1px solid $orange;
            margin-right: 20px;

            a {
                color: $paper;

                &:hover {
                    color: $paper !important;
                }
            }
        }

        .button2 {
            background: $paper;
            color: $black;
            border: 1px solid #000;

            a {
                color: $black;
            }
        }
    }

    .startdateEnd {
        display: flex;
    }

    .schedule {
        padding-right: 30px;

        .setDate {
            padding: 10px 20px;
            background: #f7f7f7;
            border-radius: 8px;

            h6 {
                margin-bottom: 0px;
                text-transform: uppercase;
                font-size: 10px;
                line-height: 16px;
                font-weight: 700;
                color: $black;
            }

            .date {
                position: relative;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: $black;
                cursor: pointer;

                svg {
                    margin-left: 4px;
                    color: $orange;
                    vertical-align: bottom;
                }
            }
        }
    }
}

.css-1hvt842-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    outline: none;
    color: $orange;
    border-color: $orange;

    &:active,
    &:visited,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:target {
        border-color: $orange;
    }
}

.css-1mzd87q-MuiPaper-root-MuiCard-root,
.css-1329v4x-MuiDivider-root {
    border-color: #0000001f;
}

.css-1t7t7fh-MuiButtonBase-root-MuiButton-root:hover {
    border: 1px solid $orange;
}

.css-gcc2o7-MuiButton-startIcon {
    color: $orange;
}

.css-17pmaar-MuiCardContent-root {
    padding: 0px;
}

p.MuiTablePagination-displayedRows {
    margin: 0px;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #ececec;
}

.MuiInputLabel-root.Mui-error {
    color: #000000;
}

.sideNavBar {
    position: fixed;
    right: 0px;
    top: 0px;
    background: $grey50;
    width: 80px;
    height: 100%;
    text-align: center;
    padding: 24px 16px;
}

.accordion1 {
    .accordionHead {
        padding: 0px;
    }

    .accordionBody {
        padding: 0px;
    }

    .Mui-expanded {
        margin-bottom: 0px;
    }

    h5 {
        font-size: 18px;
        margin-bottom: 24px;
        line-height: 24px;
        font-weight: 600;
        color: $black;
    }

    &.Mui-expanded {
        margin: 0px;
    }
}
.css-636293 {
    margin: 0 !important;
}
.MuiPaper-elevation {
    .css-19midj6 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
.css-1yuhvjn {
    margin-top: 0px;
    .MuiButton-text {
        box-shadow: none;
        outline: none;
        padding: 4px 8px;
        border-radius: 4px;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        background: $orange;
        color: $paper;
    }
}
.btn-info.disabled,
.btn-info:disabled {
    background-color: $orange;
    border-color: $orange;
}
.MuiAutocomplete-popper {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px !important;
    margin-bottom: 20px;
}

.MuiPaper-elevation16 {
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
        margin-left: -16px;
    }
}
.table1 {
    tr {
        th {
            border: none;
            padding: 12px;
            color: $black;
        }
        td {
            border: none;
            padding: 12px;
            color: $black;
        }
    }
}
.table1-heading { 
    h5 {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    }
    
}

.no-draft {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
        max-width: 450px;
    }
    h6 {
        text-align: center;
    }
    &.tree-no-data{
        img {
            max-width: 100%;
        }
        h6 {
            display: block !important;
            color: #000 !important;
        }
    }
}
