.fullWidth {
    .MuiInputBase-root {
        &:hover {
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            &::before {
                border-bottom: none;
            }
        }
        &::after {
            border-bottom: none;
        }
        .MuiInputBase-input {
            &:focus {
                background: transparent;
            }
        }
    }
}
